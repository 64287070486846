<!--
 * @Author: wxb
 * @Date: 2021-07-02 14:29:14
 * @LastEditTime: 2021-09-17 14:46:52
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Document\index.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='4'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>搜文库</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="libary-search">
        <div class="libary-search-input">
          <el-input class="libary-search-field"
                    v-model="keywords"
                    placeholder="输入关键词搜索.."
                    clearable
                    @keyup.enter.native="searchInfo"></el-input>
          <button type="button"
                  class="libary-search-btn"
                  @click="searchInfo">搜索</button>
        </div>
      </div>
      <div class="libary">
        <div class="libary-category">
          <div class="libary-category-containner">
            <div class="libary-category-title">分类</div>
            <div class="libary-category-content">
              <div class="libary-category-content-filter">
                <el-input placeholder="输入关键字过滤分类"
                          clearable
                          v-model="filterText">
                </el-input>
              </div>
              <div class="libary-category-content-tree">
                <el-tree class="filter-tree"
                         :data="data"
                         :props="defaultProps"
                         highlight-current
                         :filter-node-method="filterNode"
                         @current-change="handleNodeChange"
                         node-key="key"
                         ref="tree">
                </el-tree>
              </div>
            </div>
          </div>

        </div>
        <div class="libary-content">
          <div class="sort-list">
            <span :class="{curr:activeSort==0}"
                  @click="handleClickSort(0)">默认排序</span>
            <span :class="{curr:activeSort==1}"
                  @click="handleClickSort(1)">发布时间<i :class="{'el-icon-bottom':useRate==='desc','el-icon-top':useRate==='asc'}"></i></span>
          </div>
          <div class="datainfo"
               v-show="!noData && canSee">
            <div class="libary-list">
              <div class="libary-item"
                   v-for="(item,index) in resultLst"
                   :key="index"
                   @click="handleClickItem(item.id,item.type)">
                <div class="libary-item-img">
                  <div class="libary-item-type">
                    <img :src="item.fileType" />
                  </div>
                </div>
                <div class="libary-item-content">
                  <div class="libary-item-content-title">
                    <span v-html="formatTitle(item.standardno)"></span><span v-html="formatTitle(item.title)"></span>
                  </div>
                  <div class="libary-item-content-tag">
                    <div class="libary-item-content-tag-item"
                         v-show="item.type!=='资料中心'">发布时间：{{item.releaseDate}}</div>
                    <div class="libary-item-content-tag-item"
                         v-show="item.type!=='资料中心'">实施时间：{{item.implementationDate}}</div>
                    <div class="libary-item-content-tag-item"
                         v-show="item.type!=='资料中心'">废止时间：{{item.abolishDate}}</div>
                    <div class="libary-item-content-tag-item">{{item.standardType}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="libary-page">
              <el-pagination background
                             layout="prev, slot, next"
                             :total="total"
                             :page-size="15"
                             @current-change="handleCurrentChange"
                             :current-page.sync="currentPage">
                <span class="current-page">{{currentPage}}</span>
              </el-pagination>
            </div>
          </div>

          <noData tips="哎呀，未搜索到相关文库信息"
                  v-show="noData"></noData>
          <viporlogin bgStr="5"
                      :type="viporlogin"
                      v-show="!canSee"></viporlogin>
        </div>
      </div>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import dialogviporlogin from '@/components/dialogviporlogin'
import { mapGetters } from 'vuex'
import { search } from '@/api/document'
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'libary',
  components: {
    headerNav,
    footerNav,
    noData,
    viporlogin,
    dialogviporlogin
  },
  data() {
    return {
      filterText: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      keywords: '',
      activeSort: 0,
      useRate: 'desc',
      noData: false,
      canSee: true,
      viporlogin: 'login',
      searchForm: {
        keyword: '',
        type: '1',
        selectNode: '0',
        nodeType: '',
        node: '',
        parentNode: '',
        laver: '',
        field: '',
        id: '',
        columnName: '',
        order: '',
        start: 0,
        limit: 15
      },
      resultLst: [],
      total: 0,
      currentPage: 1,
      isSearching: false
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleClickItem(id, type) {
      const authObj = authenticate('libary:click', this.memberType)
      if (authObj.flag) {
        let nodeType = '1'
        if (type === '标准规范') {
          nodeType = '1'
        } else if (type === '标准图集') {
          nodeType = '2'
        } else if (type === '法律法规') {
          nodeType = '3'
        } else if (type === '资料中心') {
          nodeType = '4'
        }
        const routeData = this.$router.resolve({
          name: 'libaryDetail',
          params: {
            id,
            type: nodeType
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    },
    // 点击搜索或者回车搜索
    searchInfo() {
      // 清除选中高亮，从0开始恢复未选择左侧分类状态
      if (this.isSearching) {
        return
      }
      this.isSearching = true
      this.$refs.tree.setCurrentKey(null)
      this.filterText = ''
      this.searchForm.start = 0
      this.searchForm.selectNode = '0'
      this.searchForm.nodeType = ''
      this.searchForm.node = ''
      this.searchForm.parentNode = ''
      this.searchForm.laver = ''
      this.searchForm.field = ''
      this.searchForm.id = ''
      // 关键词不改变
      if (this.keywords === this.searchForm.keyword) {
        this.searchForm.type = '0'
      } else {
        // 关键词改变
        this.searchForm.keyword = this.keywords
        this.searchForm.type = '1'
      }
      this.currentPage = 1
      // 调用列表接口
      this.getLibaryList()
    },
    // 排序
    handleClickSort(index) {
      // 排序，左侧树不需要重新生成，从头开始
      this.searchForm.type = '0'
      this.searchForm.start = 0
      if (this.activeSort === index) {
        switch (index) {
          case 0:
            // 排序字段清空，排序规则清空
            this.searchForm.columnName = ''
            this.searchForm.order = ''
            break
          case 1:
            this.searchForm.columnName = 'releaseDate'
            if (this.useRate === 'desc') {
              this.useRate = 'asc'
              this.searchForm.order = 'asc'
            } else {
              this.useRate = 'desc'
              this.searchForm.order = 'desc'
            }
            break
          default:
            break
        }
      } else {
        this.activeSort = index
        if (index === 1) {
          this.searchForm.columnName = 'releaseDate'
          this.searchForm.order = this.useRate
        } else if (index === 0) {
          this.searchForm.columnName = ''
          this.searchForm.order = ''
        }
      }

      this.getLibaryList()
    },
    // 鉴权
    authCheck() {
      const authObj = authenticate('libary', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    // 初始化页面
    initPage() {
      this.isSearching = true
      this.getLibaryList()
    },
    // 处理树形节点改变
    handleNodeChange(data, node) {
      // 改变意味着选中左侧分类，需要将值设为1
      this.searchForm.selectNode = '1'
      // 同时意味着搜索关键词不变
      this.searchForm.type = '0'
      this.searchForm.node = data.label
      this.searchForm.start = 0
      // 先恢复原始状态
      this.searchForm.parentNode = ''
      this.searchForm.laver = ''
      this.searchForm.field = ''
      this.searchForm.id = ''
      // 根据不同类型传值
      if (data.type === '标准规范') {
        this.searchForm.nodeType = '1'
        if (node.level === 2) {
          this.searchForm.laver = data.laver
        } else if (node.level === 3) {
          this.searchForm.laver = data.laver
          this.searchForm.field = data.field
          this.searchForm.parentNode = node.parent.data.label
        }
      } else if (data.type === '标准图集') {
        this.searchForm.nodeType = '2'
        this.searchForm.laver = data.laver
        this.searchForm.id = data.id
      } else if (data.type === '法律法规') {
        this.searchForm.nodeType = '3'
      } else if (data.type === '资料中心') {
        this.searchForm.nodeType = '4'
        this.searchForm.laver = data.laver
        this.searchForm.id = data.id
      }
      this.currentPage = 1
      // 调用列表接口
      this.getLibaryList()
    },
    // 查询
    getLibaryList() {
      search(this.searchForm).then(res => {
        if (res.ok) {
          this.isSearching = false
          if (res.cnt > 0) {
            const list = res.lst
            for (const item of list) {
              if (item.enclosure !== '') {
                const enclosure = JSON.parse(item.enclosure)
                if (enclosure.length > 0) {
                  const name = enclosure[0].name.split('.')
                  const fileType = name[name.length - 1].toLowerCase()
                  if (fileType.indexOf('doc') !== -1) {
                    item.fileType = require('../../assets/document/word.png')
                  } else if (fileType.indexOf('ppt') !== -1) {
                    item.fileType = require('../../assets/document/ppt.png')
                  } else if (fileType.indexOf('xls') !== -1) {
                    item.fileType = require('../../assets/document/excel.png')
                  } else if (fileType.indexOf('pdf') !== -1) {
                    item.fileType = require('../../assets/document/pdf.png')
                  } else if (fileType.indexOf('txt') !== -1) {
                    item.fileType = require('../../assets/document/txt.png')
                  } else if (fileType.indexOf('png') !== -1 || fileType.indexOf('jpg') !== -1) {
                    item.fileType = require('../../assets/document/img.png')
                  } else if (fileType.indexOf('mp4') !== -1) {
                    item.fileType = require('../../assets/document/video.png')
                  } else {
                    item.fileType = require('../../assets/document/other.png')
                  }
                } else {
                  item.fileType = require('../../assets/document/info.png')
                }
              } else {
                item.fileType = require('../../assets/document/info.png')
              }
            }
            console.log(list)
            this.resultLst = list
            if (this.searchForm.type === '1') {
              this.data = []
              this.recurrenceTree(res.tree, this.data)
            }
            if (res.cnt > 150) {
              this.total = 150
            } else {
              this.total = res.cnt
            }
            this.noData = false
          } else {
            this.noData = true
            this.resultLst = []
          }
        } else {
          this.isSearching = false
          this.$message.error('获取信息异常，请稍后再试')
        }
      }).catch(res => {
        console.log(res)
      })
    },
    recurrenceTree(tree, newTree) {
      for (const item of tree) {
        const tempData = JSON.parse(JSON.stringify(item))
        tempData.key = uuidv4()
        if (item.children && item.children.length > 0) {
          tempData.children = []
          this.recurrenceTree(item.children, tempData.children)
        }
        newTree.push(tempData)
      }
    },
    // 分页事件
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.searchForm.type = '0'
      this.getLibaryList()
    },
    // 标红搜索关键词
    formatTitle(titleInfo) {
      const strReturn = titleInfo.replace(new RegExp(this.searchForm.keyword, 'g'), `<span class="com-highlight">${this.searchForm.keyword}</span>`)
      return strReturn
    }
  },
  mounted() {
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.libary {
  display: flex;
  &-category {
    width: 30rem;

    &-containner {
      border: 0.1rem solid #cfcfcf;
      border-top: 0.3rem solid #2982bf;
      border-bottom: 4rem solid #2982bf;
      min-height: 45rem;
    }

    &-title {
      font-size: 1.6rem;
      color: #2982bf;
      padding: 1rem 0 1rem 1.5rem;
    }

    &-content {
      border-top: 0.1rem solid #cfcfcf;

      &-filter {
        padding: 1rem;
      }

      &-tree {
        padding: 0 1rem 1rem;
        /deep/
          .el-tree--highlight-current
          .el-tree-node.is-current
          > .el-tree-node__content {
          color: #2982bf;
          font-weight: 700;
          background: #fff;
        }
      }
    }
  }

  &-content {
    flex: 1;
    min-height: 75rem;
    padding-left: 1rem;
    .sort {
      &-list {
        width: 100%;
        background: #f1f1f1;
        border-bottom: 1px solid #e7e3e7;
        border-top: 1px solid #ddd;
        padding: 6px 8px;

        span {
          display: inline-block;
          padding: 0 10px;
          height: 30px;
          border: 1px solid #ccc;
          line-height: 29px;
          margin-right: -1px;
          font-size: 14px;
          background: #fff;
          color: #333;
          cursor: pointer;
          &.curr {
            background: #398ce2;
            border: 1px solid #398ce2;
            color: #fff;
            &:hover {
              position: relative;
              border: 1px solid #398ce2;
              color: #fff;
              margin-right: 0;
            }
          }

          &:hover {
            position: relative;
            border: 1px solid #398ce2;
            color: #398ce2;
            margin-right: 0;
          }
        }
      }
    }

    .libary-list {
      .libary-item {
        display: flex;
        align-items: center;
        height: 9rem;
        padding: 0.5rem;
        border-bottom: 0.1rem solid #ddd;
        cursor: pointer;
        &-img {
          width: 10rem;
          text-align: center;
        }
        &-type {
          color: #fff;
          line-height: 24px;
          margin: 0 auto;
          img {
            width: 5.5rem;
            height: auto;
          }
        }

        &-content {
          flex: 1;
          height: 6.1rem;
          &-title {
            font-weight: 700;
            padding: 0.4rem 0;
            font-size: 1.4rem;
          }

          &-tag {
            display: flex;
            margin-top: 0.5rem;
            &-item {
              flex: 1;
            }
          }
        }
      }
    }
  }
}

// 搜索框
.libary {
  margin-top: 2rem;
  &-search {
    background: #ffffff;
    padding: 0px 50px;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      margin: 0 auto;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 分页
.libary-page {
  padding: 10px;
  text-align: right;
}
</style>
